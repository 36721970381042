<template>
  <VRow>
    <VCol>
      <ArchivesTableContainer />
    </VCol>
  </VRow>
</template>

<script>
// eslint-disable-next-line
import ArchivesTableContainer from '../../components/reports/ArchivesTableContainer.vue';

export default {
  name: 'ArchivesList',

  components: {
    ArchivesTableContainer,
  },

  inject: ['Names'],
};
</script>
