<template>
  <VCard>
    <VCardTitle>
      <VRow align="center">
        <VCol cols="6">
          <TTTextField
            v-model="search"
            class="tt-text-body-2"
            placeholder="Поиск"
          >
            <template #append>
              <VIcon>
                fal fa-search
              </VIcon>
            </template>
          </TTTextField>
        </VCol>
      </VRow>
    </VCardTitle>
    <VRow>
      <VCol>
        <TTChipGroup
          v-model="statusFilter"
          multiple
          class="ma-4 mt-0"
        >
          <TTChip
            v-for="(status, i) in statuses"
            :key="i"
            :value="status"
          >
            {{ status }}
          </TTChip>
        </TTChipGroup>
      </VCol>
    </VRow>
    <VDataTable
      class="elevation-1"
      :headers="headers"
      :items="computedArchives"
      :loading="loading"
      :expanded.sync="expanded"
      :search="search"
      show-expand
    >
      <template #top>
        <VToolbar flat>
          <VToolbarTitle>Архивы PDF</VToolbarTitle>

          <VSpacer />

          <slot name="topActions" />
        </VToolbar>
      </template>

      <template #item.actions="{ item }">
        <slot
          name="rowActions"
          :item="item"
        />
      </template>

      <template #item.status="{ item }">
        <VChip
          v-bind="chipStyle(item.status)"
          small
          :outlined="false"
        >
          {{ item.status }}
        </VChip>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="ma-4">
            <div
              v-for="el in Object.keys(item)"
              :key="`${item.id}_${el}`"
              class="d-flex"
            >
              <div
                class="mr-1 font-weight-bold"
              >
                {{ el }}:
              </div>
              <div class="d-flex align-center">
                <div
                  v-if="el !== 'archiveUrl'"
                >
                  {{ item[el] }}
                </div>
                <TTBtn
                  v-if="el === 'companyId'"
                  icon
                  x-small
                  class="mt-n1"
                  title="Открыть компанию в новой вкладке"
                  @click="goToCompany(item.companyId)"
                >
                  <VIcon
                    size="12"
                    class="tt-blue--text text-center"
                  >
                    $externalLink
                  </VIcon>
                </TTBtn>

                <RouterLink
                  v-if="el === 'userId'"
                  class="d-inline-flex ml-2 mt-n1 tt-light-blue--text"
                  :to="{
                    name : Names.R_AUTHENTICATION_USER,
                    params : { userId : item.userId }
                  }"
                  target="_blank"
                  title="Открыть пользователя в новой вкладке"
                >
                  <VIcon
                    size="12"
                    class="tt-blue--text valign-middle"
                  >
                    $externalLink
                  </VIcon>
                </RouterLink>
                <a
                  v-if="el === 'archiveUrl'"
                  :href="item.archiveUrl"
                  target="_blank"
                >
                  {{ item.archiveUrl }}
                </a>
              </div>
            </div>
          </div>
        </td>
      </template>
    </VDataTable>
  </VCard>
</template>

<script>
const ARCHIVE_STATUSES = {
  INIT: 'init',
  PROCESSING: 'processing',
  ARCHIVING: 'archiving',
  DONE: 'done',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
};

export default {
  name: 'ArchivesTable',

  inject: ['Names'],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    archives: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Survey ID',
          align: 'left',
          value: 'surveyId',
        },
        {
          text: 'Статус',
          align: 'left',
          value: 'status',
        },
        {
          text: 'Обновлен',
          align: 'left',
          value: 'updatedAt',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
      expanded: [],
      search: '',
      statusFilter: [...Object.values(ARCHIVE_STATUSES)],
      statuses: [...Object.values(ARCHIVE_STATUSES)],
    };
  },

  computed: {
    computedArchives() {
      return this.archives.map((archive) => {
        const result = {
          id: archive.id,
          name: archive.name,
          status: archive.status,
          email: archive.email,
          surveyId: archive.surveyId,
          userId: archive.userId,
          companyId: archive.cookies[0].value,
          createdAt: this.$dayjs.utc(archive.createdAt).format('DD.MM.YYYY HH:mm:ss'),
          updatedAt: this.$dayjs.utc(archive.updatedAt).format('DD.MM.YYYY HH:mm:ss'),
          archiveUrl: archive.archiveUrl,
        };

        return result;
      }).filter((report) => this.statusFilter.includes(report.status));
    },
  },

  watch: {
    '$route.query.status': {
      handler() {
        this.initializeFiltersFromURL();
      },
    },
    statusFilter() {
      this.updateURL();
    },
  },

  created() {
    this.initializeFiltersFromURL();
  },

  methods: {
    updateURL() {
      const query = { ...this.$route.query };

      if (this.statusFilter.length) {
        query.status = this.statusFilter.join(',');
      } else {
        delete query.status;
      }

      // Проверяем, изменились ли параметры перед навигацией
      if (this.$route.query.status !== query.status) {
        this.$router.push({ query }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    },
    initializeFiltersFromURL() {
      const {
        status,
      } = this.$route.query;
      if (status) {
        this.statusFilter = status.split(',');
      }
    },
    async goToCompany(id) {
      try {
        const { company } = await this.$di.api.Account.getCompany({ id });
        const url = this.$router.resolve({
          name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW,
          params: {
            accountId: company.accountId,
            companyId: company.id,
          },
        }).href;
        window.open(url, '_blank');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    chipStyle(status) {
      const colors = {
        [ARCHIVE_STATUSES.INIT]: 'tt-light-gray',
        [ARCHIVE_STATUSES.PROCESSING]: 'tt-light-yellow',
        [ARCHIVE_STATUSES.ARCHIVING]: 'tt-light-blue vibrant',
        [ARCHIVE_STATUSES.DONE]: 'tt-light-green vibrant',
        [ARCHIVE_STATUSES.FAILED]: 'tt-light-orange',
        [ARCHIVE_STATUSES.CANCELLED]: 'tt-light-gray',
      };
      return { color: colors[status] };
    },
  },
};
</script>
<style lang="scss" scoped>
.valign-middle {
  vertical-align: middle;
}
</style>
