<template>
  <ArchivesTable
    :archives="archives"
    :loading="loading"
  >
    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_ENGAGEMENT_REPORTS_ARCHIVES_VIEW, params : { archiveId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>
    </template>
  </ArchivesTable>
</template>

<script>
import ArchivesTable from './ArchivesTable.vue';

export default {
  name: 'ArchivesTableContainer',

  components: {
    ArchivesTable,
  },

  inject: ['Names'],

  data() {
    return {
      archives: [],
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$di.api.ReportServiceApi
        .reportsArchivesGet()
        .then(({ archives }) => {
          this.archives = archives;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
